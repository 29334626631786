import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl } from 'helpers'
import { Layout, Section, Container, FormattedMessage } from 'components'

const TechnologiesPage = ({ data: { page }, intl: { locale } }) => {
  return (
    <Layout>
      <Section>
        <Container>
          <h1>
            <FormattedMessage id="technologies:description" />
          </h1>
        </Container>
      </Section>
      <Section>
        <Container>
          {page &&
            page.edges
              .filter(({ node }) => node.node_locale === locale)
              .map(({ node: { id, slug, title, text } }) => (
                <div
                  key={id}
                  className="content-html"
                  dangerouslySetInnerHTML={{
                    __html: text.childMarkdownRemark.html,
                  }}
                />
              ))}
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(TechnologiesPage)

export const query = graphql`
  {
    page: allContentfulPage(filter: { slug: { eq: "technologies" } }) {
      edges {
        node {
          id
          slug
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          node_locale
        }
      }
    }
  }
`
